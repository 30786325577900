<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <!-- <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item> -->
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                        <div class="title-div">现有公益分红金额<span class="num">￥{{teamMoney}}</span></div>
                    </div>

                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 105px)" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="detailCode" label="订单编号" width="250px">
                    </el-table-column>
                    <el-table-column prop="goodsName" label="商品名称" width="250px">
                    </el-table-column>
                    <el-table-column prop="goodsSpecification" label="规格" align="center">
                    </el-table-column>
                    <el-table-column prop="user.userName" label="购买用户" align="center">
                    </el-table-column>
                    <el-table-column prop="user.account" label="购买账号" align="center">
                    </el-table-column>
                    <el-table-column prop="shareMoney" label="公益资金（元）" align="center">
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.state == 0" type="warning" size="small">待入账</el-tag>
                            <el-tag v-if="scope.row.state == 1" type="success" size="small">已完成</el-tag>
                            <el-tag v-if="scope.row.state == -1" type="info" size="small">已拒绝</el-tag>
                            <el-tag v-if="scope.row.state == -2" type="danger" size="small">取消</el-tag>
                            <el-tag v-if="scope.row.state == -3" type="danger" size="small">已退款</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payTime" label="交易时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.payTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dateTime" label="入账时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.payTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Distributor } from "../../../components/HospitalDomain/Distributor";

export default {
    name: "OwnStats",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            Keyword: "",
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            id:this.$route.query.id,
            teamMoney:this.$route.query.teamMoney,
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            ShareData:{}
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            this.DistributorDomain.OrgMyTeamPublickShareDetail(_this.id,_this.searchForm.keyWord, _this.PageIndex,
                (data) => {
                    this.tableData = data.data.results;
                    this.PageIndex = data.data.pageIndex;
                    this.PageSize = data.data.pageSize;
                    this.PageTotal = data.data.pageCount;
                    this.DataTotal = data.data.dataTotal;
                }, (err) => {
                    console.log(err);
                })
        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: 0;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}
.title-div{
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}
.title-div .num{
    margin-left: 10px;
    font-size: 18px;
    color: red;
}
.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}
.iq-search-bar /deep/.el-form-item {
    margin-bottom: 10px;
}
</style>
